import React, { useState } from 'react'
import axios from 'axios'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'

import styles from './page.module.css'

const IndexPage = () => {
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [response, setResponse] = useState({
    title: 'Nice to meet you!',
    content: 'We will keep you in the Loop.'
  })
  const [error, setError] = useState(false)

  const handleSubmission = async (event: React.FormEvent) => {
    event.preventDefault()
    setLoading(true)
    
    const email = event.currentTarget[0].value
    const fullname = event.currentTarget[1].value
    try {
      await axios({
        method: 'POST',
        url: 'https://awpxcmnn95.execute-api.eu-central-1.amazonaws.com/dev/email', 
        data: {
          email,
          fullname
        }
      })
      setSuccess(true)
    } catch (error) {
      if (error.response.data === 'ConditionalCheckFailedException') {
        setResponse({
          title: 'Nice to see you again!',
          content: 'It looks like you already signed up. We are so happy you are as excited as we are!'
        })
      }

      if (error.response.data === 'Bad Request') {
        setResponse({
          title: 'Oh no, something went wrong :(',
          content: 'Please try again or feel free to contact us at info@algedonicloop.io'
        })
        setError(true)
      }
      setSuccess(true)
    }
  }

  return (
    <Layout>
      <SEO title="Home" />
      <div className={`container ${styles.container} ${success && styles.hide}`}>
        <h1 className={styles.title}>Algedonic Loop</h1>
        <p>Verified data. Forever</p>
        {!loading &&
          <form className={styles.form} onSubmit={handleSubmission}>
            <p>
              Let's stay in touch!
            </p>
            <label htmlFor="email">Email Address</label>
            <div>
              <input type="email" name="email" placeholder="Your email address" tabIndex={1} required/>
              <input type="text" name="fullName" tabIndex={-1}/>
              <button>Subscribe</button>
            </div>
          </form>
        }
        {loading &&
          <div className={styles.loading}>
            <div className={styles.ldsRing}><div></div><div></div><div></div><div></div></div>
          </div>
        }
      </div>
      <div className={`${success && styles.show} ${styles.success} ${error && styles.error}`}>
        <h1 className={styles.title}>Algedonic Loop</h1>
        <h1>{response.title}</h1>
        <p>{response.content}</p>
        <a href="/">&larr; Back</a>
      </div>

    </Layout>
  )
}

export default IndexPage
